
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import TabList from '@/components/tab-list.vue'
import TableListPanel from '@/components/tablist-panel.vue'
import CurrentTimeApproval from './current-time/current-time-approval.vue'
import TeamRequestHistoryRecord from '../team-request-history-record/index.vue'

@Component({ name: 'TabApproval', components: { TabList, TableListPanel, CurrentTimeApproval, TeamRequestHistoryRecord } })
export default class extends Vue {

}
