
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import teamUseApi, { TeamUse, TeamAddStatusAgreed, TeamAddStatusRejected } from '@/api/team-user'
import { getterProjectMemberRight, getterUserInfo } from '@/store'

@Component({ name: 'CurrentTimeApproval', components: {} })
export default class extends Vue {
  stateIndex = 3;
  checkIndex = -1;
  dataList = [
    {
      logo: require('@/assets/team/kids.png'),
      time: '15:44',
      name: '李小红',
      id: '10012',
      tel: '13800138001'
    },
    {
      logo: require('@/assets/team/kids.png'),
      time: '15:46',
      name: '李小红',
      id: '10012',
      tel: '13800138001'
    }
  ];

  userList: Array<TeamUse> = [];

  get currentTitle () {
    if (this.stateIndex === 0) return '本日'
    if (this.stateIndex === 1) return '本周'
    if (this.stateIndex === 2) return '本月'
    if (this.stateIndex === 3) return '更早'
  }

  mounted () {
    this.changeIndex(3)
  }

  // 鼠标移出移入切换状态
  changeShowCheck (index: number) {
    this.checkIndex = index
  }

  // 切换日周月
  changeIndex (index: number) {
    this.stateIndex = index
    // 获取对应数据
    teamUseApi
      .queryTeamRequestList(this.stateIndex)
      .then((resp) => {
        this.userList = resp.data!
      })
      .catch((err) => {
        this.$Message.error('获取数据异常：' + err.message)
      })
  }

  // 批复通过
  pass (passItem: TeamUse) {
    teamUseApi
      .updateApplyResult(passItem.id!, TeamAddStatusAgreed)
      .then((resp) => {
        this.$Message.success('已同意！')
        this.changeIndex(this.stateIndex)
        this.checkIndex = -1
      })
      .catch((err) => {
        this.$Message.error('修改数据异常：' + err.message)
      })
  }

  // 批复拒绝
  reject (rejectItem: TeamUse) {
    teamUseApi
      .updateApplyResult(rejectItem.id!, TeamAddStatusRejected)
      .then((resp) => {
        this.$Message.success('已拒绝！')
        this.changeIndex(this.stateIndex)
        this.checkIndex = -1
      })
      .catch((err) => {
        this.$Message.error('修改数据异常：' + err.message)
      })
  }

  /**
   * 用户管理权限
   */
  get canEdit () {
    const user = getterUserInfo()
    return user.canAddUser
  }
}
