import { ToolBarCodeExportAll } from '@/frame/share/table-panel'

/// 表格基本配置
const options = {}
/// 导出列定义
const columns = [
  { type: 'checkbox', width: 40 },
  { type: 'seq', title: '序号', width: 60 },
  { field: 'createTime', title: '申请时间', sortable: true, width: 170 },
  {
    field: 'addStatusName', width: 170, title: '申请状态', slot: 'col_addStatusName'
  },
  { field: 'userCode', title: '用户ID' },
  { field: 'userPhone', title: '手机' },
  { field: 'nameAtTeam', title: '团队内名称' },
  { field: 'approverName', title: '审批人' },
  { field: 'modifyTime', title: '审批时间', sortable: true, width: 170 },
  { width: 40, slot: 'col_action' }
]

/// 导出工具栏定义
const toolbarConfig = {
  buttons: [
    { code: 'insert', name: '新增', icon: 'md-add' },
    { code: 'delete', name: '直接删除', icon: 'fa fa-trash-o', status: 'error' },
    { code: ToolBarCodeExportAll, name: '全量导出', icon: 'vxe-icon--download', status: 'success' }
  ],
  refresh: true,
  export: true,
  print: true,
  zoom: true,
  custom: true,
  exportall: true
}
/**
 * 表格的打印配置
 */
const printConfig = {
  sheetName: '打印表格'
}

/**
 * 表格的导出配置
 */
const tableExport = {
// 默认选中类型
  type: 'xlsx',
  // 自定义类型
  types: ['xlsx', 'csv', 'html', 'xml', 'txt']
}

/// 导出配置
export default {
  options: options,
  columns: columns,
  toolbar: toolbarConfig,
  printConfig: printConfig,
  tableExport: tableExport
}
